export const checkAvailability = async() => {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
        }
    };

    return _fetch_and_json( 
        "https://ifyoq432jj.execute-api.eu-central-1.amazonaws.com/availability", 
        requestOptions);
}

export const allocateOrder = async(payload) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    };

    return _fetch_and_json( 
        "https://ifyoq432jj.execute-api.eu-central-1.amazonaws.com/order", 
        requestOptions, 
    );
}
    
export const setOrderStatus = async(internalOrderId, payload) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    };

    return _fetch_and_json( 
        "https://ifyoq432jj.execute-api.eu-central-1.amazonaws.com/order/" + internalOrderId, 
        requestOptions, 
    );
}

const _fetch_and_json = async ( endpoint, options ) => {
    console.log(options.method + " " + endpoint + " ");

    try{
        let response = await fetch(endpoint, options);
        
        return {
            status: response.status,
            content: await response.json()
        }
    } catch (e){
        console.log(e)
    }  
}