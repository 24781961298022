import React from 'react';
import Stack from '@mui/material/Stack';
import {useNavigate} from 'react-router-dom';

import { Paper, Button, TextField, Box, FormControlLabel, Checkbox, Radio, Alert, CircularProgress, Snackbar } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons'
import { Forms } from '../Forms';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { allocateOrder, checkAvailability, setOrderStatus } from '../Apis';
import Countdown from 'react-countdown';
import pic1 from '../cappellini/1-min.png'
import pic2 from '../cappellini/2-min.png'
import pic3 from '../cappellini/3-min.png'

function Buy() {
    let navigate = useNavigate();
    const hatPictures = [pic3, pic2, pic1]

    const [ isProductAvailable, setProductAvailable ] = React.useState(undefined);
    const [ quantity, setQuantity ] = React.useState(0);
    const [ currentPicture, setCurrentPicture ] = React.useState(0);
    const [ shipToBill, setShipToBill ] = React.useState(true);

    /*const [ billingInfo, setBillingInfo ] = React.useState(Forms.BillingInfoForm);*/
    const [ shippingInfo, setShippingInfo ] = React.useState(Forms.ShippingInfoForm);

    const [ termsChecked, setTermsChecked ] = React.useState(false);
  
    const [ internalOrderId, setInternalOrderId ] = React.useState(undefined);
    const [ paypalOrderId, setPaypalOrderId ] = React.useState(undefined);

    const [ orderCountdown, setOrderCountdown ] = React.useState(undefined);
    const [ snackBar, setSnackBar ] = React.useState({severity: 'error', message: undefined});

    const [ updatingOrderStatus, setUpdatingOrderStatus ] = React.useState(false)
    const shippingRef = React.useRef(null);

    React.useEffect(() => {
      if( quantity > 5){
        setQuantity(5);
        setSnackBar({severity: 'error', message: 'Massimo 5 a persona amico!'})
      }else{
        if(internalOrderId && termsChecked){
          const orderToReject = internalOrderId;
          setInternalOrderId(undefined);

          setOrderStatus(orderToReject, {'status': 'REJECTED', 'paypal_details': {}}).then((res) => {
            createOrder();
          });
        }
      }
    }, [quantity]);

    /*React.useEffect(() => {
      if(internalOrderId){
        console.log("ORDER ID " + internalOrderId)
      }
    }, [internalOrderId]);*/

    React.useEffect(() => {
      if(!termsChecked){
        return;
      }

      if(internalOrderId === undefined){
        createOrder();
      }
    }, [termsChecked])

    React.useEffect(() => {
      checkAvailability().then((res)=>{
        setProductAvailable(res.content.is_hat_available === true)
      })
    }, [])

    const createOrder = () => {
      allocateOrder({quantity: quantity, email: shippingInfo.email.currValue, phone: shippingInfo.phone.currValue}).then((res) => {
        if(res.status === 200 && res.content.order_number){
          setInternalOrderId(res.content.order_number)
          
          if(orderCountdown === undefined){
            setOrderCountdown(Date.now() + 3*60*1000)
          }
        }else{
          setSnackBar({severity: 'error', message: 'Non abbiamo più cappellini! Riprova fra poco...'});
          setTermsChecked(false);
          setInternalOrderId(undefined);
        }
      })
    }

    const onTermsChange = (checked) => {
      if(!checked){
        setTermsChecked(checked);
      }else if(validateShippingInfo()){
        setTermsChecked(checked);
      }else{
        shippingRef.current.scrollIntoView() 
      }
    }

    const validateShippingInfo = () => {
      let errors = false;
      let newShippingInfo = Object.assign({}, shippingInfo);

      Object.entries(shippingInfo).forEach(([key, item]) => {
        if(item['required'] && !item['currValue']){
          errors = true;
          newShippingInfo = {
            ...newShippingInfo,
            [key]: {
              ...newShippingInfo[key],
              error: 'Questo campo è obbligatorio'
            }
          }
        }

        if(key === 'email'){
          const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
          if(!item['currValue'] || regex.test(item['currValue']) === false){
            errors = true;
            newShippingInfo = {
              ...newShippingInfo,
              [key]: {
                ...newShippingInfo[key],
                error: 'Inserisci un indirizzo email valdio'
              }
            }
          }
        }
      })

      setShippingInfo(newShippingInfo);
      return !errors;
    }

    const onSnackbarClose = () => {
      setSnackBar({...snackBar, message: undefined})
    }

    const renderCheckout = () => {
      return (
          <>
          <Snackbar open={snackBar.message !== undefined} autoHideDuration={6000} onClose={() => onSnackbarClose()}>
            <Alert anchorOrigin={{ horizontal: 'center' }} onClose={() => onSnackbarClose()} severity={snackBar.severity} sx={{ width: '100%' }}>
              {snackBar.message}
            </Alert>
          </Snackbar>

          <Stack direction="column" spacing={2} style={{paddingBottom: '2em'}}>

            <Paper sx={{backgroundColor: 'transparent', height: '23em'}} elevation={0}>
              <Stack direction="row" style={{height: '90%'}}>
                <div className="pictureContainer" style={{backgroundImage: `url(${hatPictures[currentPicture]})`}}></div>
              </Stack>

              <Stack style={{height: '10%'}} direction="row" justifyContent={"center"}>
                <Radio
                    size='small'
                    checked={currentPicture === 0}
                    onChange={() => setCurrentPicture(0)}
                  />
                  <Radio
                    size='small'
                    checked={currentPicture === 1}
                    onChange={() => setCurrentPicture(1)}
                  />
                  <Radio  
                    size='small'
                    checked={currentPicture === 2}
                    onChange={() => setCurrentPicture(2)}
                  />
                </Stack>
            </Paper>
            
            <Stack direction="column" spacing={1}>
              <span className="itemTitle">BERRETTO<br />PAPERELLA</span>
              <span className="itemPrice"> 18,00 &euro;&nbsp;&nbsp;<span style={{fontSize: "0.6em"}}>IVA inclusa</span></span>
              <span className="itemSize" style={{marginTop: '-0.2em'}}> Taglia unica</span>
            </Stack>

            {
              quantity === 0 ? (
                <>
                <Button 
                    onClick={() => setQuantity( 1 ) } 
                    className="buyButton" 
                    variant="contained" 
                    disabled={!isProductAvailable}
                    style={{
                        backgroundColor: ( (isProductAvailable) ? '#ffeb01' : '' ),
                    }}
                    sx={{width: "100%"}}>
                  {
                    isProductAvailable === undefined ? (
                      <Stack direction="row" spacing={2}>
                        <CircularProgress 
                          color="inherit"
                          size={25}
                        /> 
                        <span>
                          Controllo se ne abbiamo ancora
                        </span>
                      </Stack>
                    ) : (
                      isProductAvailable ? (
                        "FIGO! LO VOGLIO"
                      ) : (
                        <Stack direction="row" spacing={1}><span>OUT OF STOCK</span></Stack>
                      )
                    )
                  }
                </Button>
                {
                  isProductAvailable === false &&
                  <p className="waitForMore">
                    Ne stiamo cucendo altri... rimani sintonizzata sui canali social di Anastasio per i prossimi drop!
                  </p>
                }
                </>
              ) : (
                <Stack direction="column" spacing={1}>
                  <span className="itemPrice" style={{color: '#ffeb01', textAlign: 'center'}}>Quanti ?</span>

                  <Stack direction="row" spacing={2} sx={{alignItems: 'center', justifyContent: 'center'}}>
                      {
                        internalOrderId === undefined &&
                        <FontAwesomeIcon onClick={() => setQuantity( quantity-1 ) } style={{fontSize: '2em', cursor: 'pointer', color: '#ffeb01'}} icon={faCircleMinus} />
                      }
                      <div className="quantityCounter">
                        {quantity}
                      </div>
                      {
                        internalOrderId === undefined &&
                        <FontAwesomeIcon onClick={() => setQuantity( quantity+1 ) } style={{fontSize: '2em', cursor: 'pointer', color: '#ffeb01'}} icon={faCirclePlus} />
                      }
                  </Stack>

                  {
                    shipToBill && (
                      <>
                      <span ref={shippingRef} className="itemTitle" style={{fontSize: '1.8em', marginTop: '1em'}}>INDIRIZZO DI SPEDIZIONE</span>
                      <Box
                        component="form"
                        sx={{
                          padding: 0,
                          '& .MuiTextField-root': { m: 1, marginLeft: 0, width: '100%', backgroundColor: 'white'}
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <div>
                          {
                            Object.entries(Forms.ShippingInfoForm).map( ([key, value])  => (
                              <TextField
                                error={shippingInfo[key].error !== undefined}
                                required={value.required}
                                key={"shipping-info-" + key}
                                id={"shipping-info-" + key}
                                label={value.display}
                                variant="filled"
                                helperText={shippingInfo[key].error}
                                value={shippingInfo[key].currValue}
                                onChange={(e) => setShippingInfo({...shippingInfo, [key]: { ...shippingInfo[key], currValue: e.target.value, error: undefined } })}
                                inputProps={
                                  { readOnly: value.readOnly || termsChecked }
                                }
                              />
                            ))
                          }
                        </div>
                      </Box>
                      </>
                    )
                  }

                  { /* ORDER RECAP */ }
                  <span className="itemTitle" style={{fontSize: '1.8em', marginTop: '0.8em'}}>IL TUO ORDINE</span>
                  
                  <table className="recapTable" style={{width: "100%"}}>
                      <tr>
                        <td className="l"><b>{quantity}</b> x</td>
                        <td style={{fontWeight: 800}}>BERRETTO PAPERELLA</td>
                      </tr>
                      <tr>
                        <td className="l"><b>Subtotale</b></td>
                        <td>{18*quantity},00 &euro;</td>
                      </tr>
                      <tr>
                        <td className="l"><b>Spedizione</b></td>
                        <td>3,50 &euro;</td>
                      </tr>
                      <tr style={{fontWeight: 'bold', fontSize: '1.5em'}}>
                        <td style={{lineHeight: '1.4em'}} className="l"><b>Totalone</b></td>
                        <td style={{lineHeight: '1.4em'}}>{18*quantity+3.5}0 &euro;</td>
                      </tr>
                  </table>

                  { /* PAYMENT */ }
                  <FormControlLabel 
                    control={
                      <Checkbox 
                        checked={termsChecked}
                        iconStyle={{fill: 'white'}}
                        onChange={(e) => onTermsChange(e.target.checked) }
                          />
                    } 
                    label={<span className="termsSpan" style={{fontSize: '0.7em'}}>Ho letto e accetto i <a href="#">termini e condizioni</a> del sito web</span>} />

                  {
                    termsChecked && !internalOrderId &&
                    <CircularProgress 
                      color="inherit"
                      size={25}
                    /> 
                  }

                  {
                    termsChecked && internalOrderId &&
                    <>
                      <span className="itemTitle" style={{fontSize: '1.8em', marginTop: '0.5em'}}>PAGAMENTO</span>

                      <Alert severity="info">
                        Completa il pagamento in&nbsp;<b>
                        {
                          <Countdown
                            onStop={() => alert("finito")} 
                            date={orderCountdown} />
                        } 
                        </b>&nbsp;altrimenti saremo costretti a dare
                        {
                          quantity === 1 ? (
                            ' il tuo cappellino'
                          ) : (
                            ' i tuoi cappellini'
                          )
                        }
                        &nbsp;a qualcun altro
                      </Alert>

                      <PayPalScriptProvider options={{ 
                          "client-id": "Ae08aGJ4-BxvsqNmK2DD1WXHMSXPkHVBTa-LnrIdiSRt6pdOQfLDT5JVF1thAp6eJdrOkW53WiAQpu3f",
                          "locale": 'it_IT',
                          "currency": "EUR",
                          "intent": "authorize"
                        }}>
                          <PayPalButtons 
                            funding={undefined}
                            showSpinner={true}
                            forceReRender={[quantity]}
                            intent="authorize"
                            style={{ 
                              layout: "vertical", 
                              color: "white",
                              tagline: false
                            }}
                            onError={(err) => {
                              setUpdatingOrderStatus(true);

                              setOrderStatus(internalOrderId, {'status': 'REJECTED', 'paypal_details': {}}).then((res) => {
                                navigate(`/error`)
                              });
                            }}
                            onApprove={function (data, actions) {
                              return actions.order.authorize().then(function (e) {
                                setUpdatingOrderStatus(true);

                                e['purchase_units'][0]['shipping'] = {}
                                
                                setOrderStatus(internalOrderId, {'status': 'ACCEPTED', 'paypal_details': e}).then((res) => {
                                  if(res.status === 200){
                                    navigate(`/success/${internalOrderId}`)
                                  }else{
                                    navigate(`/error`)
                                  }
                                })
                              });
                            }}
                            createOrder={(data, actions) => {
                              return actions.order
                                  .create({
                                      purchase_units: [
                                          {
                                              amount: {
                                                  currency_code: "EUR",
                                                  value: quantity*18+3.5,
                                                  breakdown: {
                                                    item_total: {value: (quantity*14.76).toFixed(2), currency_code: 'EUR'},
                                                    shipping: {value: 3.5, currency_code: 'EUR'},
                                                    tax_total: {value: (quantity*3.24).toFixed(2), currency_code: 'EUR'}
                                                  }
                                              },
                                              items: [{
                                                name: 'Cappellino Paperella',
                                                unit_amount: {value: '14.76', currency_code: 'EUR'},
                                                quantity: quantity
                                              }],
                                              shipping:{
                                                name:{
                                                    full_name: `${shippingInfo['name']['currValue']} ${shippingInfo['surname']['currValue']}`
                                                },
                                                //email_address: shippingInfo['email']['currValue'],
                                                
                                                address: {
                                                    address_line_1: `${shippingInfo['street']['currValue']}`,
                                                    address_line_2: shippingInfo['street_2']['currValue'] || '',
                                                    admin_area_2: `${shippingInfo['city']['currValue']}`,
                                                    admin_area_1: `${shippingInfo['state']['currValue']}`,
                                                    postal_code: `${shippingInfo['zipcode']['currValue']}`,
                                                    country_code: 'IT'
                                                }
                                              }
                                          },
                                      ],
                                      application_context: {
                                        shipping_preference: "SET_PROVIDED_ADDRESS",
                                      },
                                  })
                                .then((orderId) => {
                                    setPaypalOrderId(orderId);
                                    return orderId;
                                });
                            }}
                          />
                      </PayPalScriptProvider>
                    </>
                  }
                </Stack>
              )
            }
            
          </Stack>
          </>
      );
    }

    const renderHugeSpinner = () => {
      return (
        <div className='spinnerContainer'>
          <CircularProgress
            color="inherit"
            size={80}
          />
        </div>
      )
    }

    /* RET FUNCTION */
    if( updatingOrderStatus ){
      return renderHugeSpinner()
    }else{
      return renderCheckout()
    }
  }
  
  export default Buy;