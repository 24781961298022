export var Forms = {
    BillingInfoForm: {
        name: { display: 'Nome', required: true, readOnly: false, defaultValue: undefined, currValue: ""}
        ,surname: { display: 'Cognome', required: true, readOnly: false, defaultValue: undefined, currValue: ""}
        ,country: { display: 'Stato', required: true, readOnly: true, defaultValue: undefined, currValue: "Italia"}
        ,street: { display: 'Via / Piazza e Numero Civico', required: true, readOnly: false, defaultValue: undefined, currValue: ""}
        ,steet_2: {display: 'Appartamento / Suite / Unità (opzionale)', required: false, readOnly: false, defaultValue: undefined, currValue: ""}
        ,zipcode: {display: 'CAP', required: true, readOnly: false, defaultValue: undefined, currValue: ""}
        ,city: {display: 'Città', required: true, readOnly: false, defaultValue: undefined, currValue: ""}
        ,state: {display: 'Provincia', required: true, readOnly: false, defaultValue: undefined, currValue: ""}
        ,phone: {display: 'Cellulare', required: true, readOnly: false, defaultValue: undefined, currValue: ""}
        ,email: {display: 'Indirizzo e-mail', required: true, readOnly: false, defaultValue: undefined, currValue: ""}
    },

    /*ShippingInfoForm: {
        name: { error: undefined, display: 'Nome', required: true, readOnly: false, defaultValue: undefined, currValue: "Pietro"}
        ,surname: { error: undefined, display: 'Cognome', required: true, readOnly: false, defaultValue: undefined, currValue: "Avolio"}
        ,country: { error: undefined, display: 'Stato', required: true, readOnly: true, defaultValue: 'Italia', currValue: "Italia"}
        ,street: { error: undefined, display: 'Via / Piazza e Numero Civico', required: true, readOnly: false, defaultValue: undefined, currValue: "Viale Carlo Espinasse 63"}
        ,street_2: { error: undefined, display: 'Appartamento / Suite / Unità (opzionale)', required: false, readOnly: false, defaultValue: undefined, currValue: ""}
        ,zipcode: { error: undefined, display: 'CAP', required: true, readOnly: false, defaultValue: undefined, currValue: "20156"}
        ,city: { error: undefined, display: 'Città', required: true, readOnly: false, defaultValue: undefined, currValue: "Milano"}
        ,state: { error: undefined, display: 'Provincia', required: true, readOnly: false, defaultValue: undefined, currValue: "Milano"}
        ,phone: { error: undefined, display: 'Cellulare', required: true, readOnly: false, defaultValue: undefined, currValue: "3338208372"}
        ,email: { error: undefined, display: 'Indirizzo e-mail', required: true, readOnly: false, defaultValue: undefined, currValue: "pietro.avolio@hotmail.it"}
    },*/

    
    ShippingInfoForm: {
        name: { error: undefined, display: 'Nome', required: true, readOnly: false, defaultValue: undefined, currValue: ""}
        ,surname: { error: undefined, display: 'Cognome', required: true, readOnly: false, defaultValue: undefined, currValue: ""}
        ,country: { error: undefined, display: 'Stato', required: true, readOnly: true, defaultValue: 'Italia', currValue: "Italia"}
        ,street: { error: undefined, display: 'Via / Piazza e Numero Civico', required: true, readOnly: false, defaultValue: undefined, currValue: ""}
        ,street_2: { error: undefined, display: 'Appartamento / Suite / Unità (opzionale)', required: false, readOnly: false, defaultValue: undefined, currValue: ""}
        ,zipcode: { error: undefined, display: 'CAP', required: true, readOnly: false, defaultValue: undefined, currValue: ""}
        ,city: { error: undefined, display: 'Città', required: true, readOnly: false, defaultValue: undefined, currValue: ""}
        ,state: { error: undefined, display: 'Provincia', required: true, readOnly: false, defaultValue: undefined, currValue: ""}
        ,phone: { error: undefined, display: 'Cellulare', required: true, readOnly: false, defaultValue: undefined, currValue: ""}
        ,email: { error: undefined, display: 'Indirizzo e-mail', required: true, readOnly: false, defaultValue: undefined, currValue: ""}
    },
}