import Stack from '@mui/material/Stack';
import logo from '../paperella_no_bordi_2.svg';
import { useParams } from 'react-router-dom';

function Confirm() {
    let { orderid } = useParams();

    return (
      <Stack direction="column" style={{textAlign: 'center'}}>
        <span className="thanks">GRAZIE!</span>
        <span style={{color: '#ffeb01', fontSize: '1.2em', fontWeight: 600}}>IL TUO BERRETTO STA PER PARTIRE</span>
        <span style={{textDecoration: 'underline'}}>Ordine numero: { orderid }</span>
        <p></p>
        <span style={{fontSize: '1.2em'}}>Riceverai una mail di recap<br />al tuo indirizzo di posta elettronica</span>
        <p></p>
        <p></p>
        <img src={logo} style={{height: '12em'}} />
      </Stack>
    )
  }
  
  export default Confirm;