import {Stack, Button} from '@mui/material';
import logo from '../paperella_no_bordi_2.svg';

function Error() {
    return (
      <Stack direction="column" style={{textAlign: 'center'}}>
        <span className="thanks">ON NO!</span>
        <span style={{color: '#ffeb01', fontSize: '1.2em', fontWeight: 600}}>SI È VERIFICATO UN ERRORE CON IL TUO ORDINE</span>
        <p></p>
        <span style={{fontSize: '1.2em'}}>Probabilmente i cappellini sono finiti mentre completavi l'ordine! Vale la pena riprovare, no?</span>
        <p></p>
        <Button 
          className="buyButton" 
          onClick={() => {window.location.href = '/'} }
          variant="contained" 
          style={{
            backgroundColor: '#ffeb01',
          }}
          sx={{width: "100%"}}>
            VOGLIO QUEL CAPPELLINO
        </Button>
        <p></p>
        <p></p>
        <img src={logo} style={{height: '12em'}} />
      </Stack>
    )
  }
  
  export default Error;