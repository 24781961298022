import Buy from './pages/Buy';
import Success from './pages/Success';
import Error from './pages/Error';
import './App.css';
import { Stack, Container } from '@mui/material';
import logo from './paperella_no_bordi_2.svg';
import OrdiniESpedizioniPDF from './paperz/ordiniespedizioni.pdf'
import TerminiECondizioniPDF from './paperz/terminiecondizioni.pdf'
import PrivacyPolicyPDF from './paperz/privacypolicy.pdf'
import CookieBot from 'react-cookiebot';
import {
  Routes,
  Route,
} from "react-router-dom";

function App() {
  return (
      <>
      <CookieBot domainGroupId={'5fe0ecbd-d6c5-42f8-9e7f-72105b85723f'} />

      <Stack direction="column" style={{minHeight: '100vh'}} justifyContent={"space-between"}>
        <Container maxWidth="sm">
          <Stack direction="column" spacing={2}>
            <Stack direction="row" className="header" justifyContent="space-between">
              <img src={logo} style={{height: '2em'}}/>
              <span style={{fontSize: '0.8em', fontWeight: '800'}}>ANASTASIO <span style={{fontSize: '0.7em'}}>/</span> OFFICIAL SHOP</span>
            </Stack>

            <Routes>
              <Route path="/" element={<Buy />} />
              <Route path="success/:orderid" element={<Success />} />
              <Route path="error" element={<Error />} />
            </Routes>

          </Stack>
        </Container>

        <Stack className="footer" direction="column" spacing={2}>
            <div>
              <a href={PrivacyPolicyPDF} target="_blank">Privacy Policy</a> <span>/</span> <a href={OrdiniESpedizioniPDF} target="_blank">Ordini e Spedizioni</a> <span>/</span> <a href={TerminiECondizioniPDF} target="_blank">Termini e Condizioni</a> <br />
            </div>

            <div style={{fontSize: '0.7em', color: 'rgba(30, 30, 30, 0.7)'}}>
              &copy; Nasta Enterprice S.R.L. 2022 / P.IVA IT11070190969 
            </div>
        </Stack>
      </Stack>
    </>
  );
}

export default App;
